<template>
  <div style="padding: 20px;height: auto;display: flex;flex-direction: column;align-items: center;justify-content: center;">
    <h1 style="margin-bottom: 50px">星任务(视频)订单投后数据</h1>
    <el-form ref="form" :model="preFrom" label-width="80px">
      <el-form-item label="账户ID">
        <el-input v-model="preFrom.advertiser_id" />
        <span style="color: #a9a9a9">必填，广告主账户Id（快手ID）</span>
      </el-form-item>
      <el-form-item label="任务ID">
        <el-input v-model="preFrom.task_ids" type="textarea" />
        <p style="color: #a9a9a9;margin: 0">必填，任务id</p>
      </el-form-item>
      <el-form-item label="订单创建时间">
        <el-date-picker
          v-model="createTime"
          type="datetimerange"
          align="right"
          range-separator="至"
          start-placeholder="	消耗开始时间"
          end-placeholder="	消耗结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
          @change="getDate"
        />
        <p style="color: #a9a9a9;margin: 0">可选，订单创建开始至结束时间最长跨度14天</p>
      </el-form-item>
      <el-form-item label="订单状态">
        <el-checkbox-group v-model="preFrom.order_status">
          <el-checkbox-button v-for="state in status" :key="state.value" :label="state.value">{{ state.label }}</el-checkbox-button>
        </el-checkbox-group>
        <p style="color: #a9a9a9;margin: 0">可选，订单状态，默认全选</p>
      </el-form-item>
      <el-form-item style="display: flex; align-items: center;justify-content: center">
        <el-button type="primary" :loading="loading" @click="onSubmit">立即获取</el-button>
        <el-button type="primary" @click="handlerExportRecord">导出记录</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :title="exportRecord.title" :visible.sync="exportRecord.open" append-to-body>
      <el-table v-loading="loading" :data="exportRecord.dataList">
        <el-table-column label="导出账户" align="center" prop="exporterName" />
        <el-table-column label="导出时间" align="center" prop="exportTime" width="180">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.exportTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="文件名" align="center" prop="fileName" />
        <el-table-column label="导出状态" align="center" prop="exportState"/>
        <el-table-column label="错误原因" align="center" prop="failReason"/>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-link :href="scope.row.exportPath">下载</el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.size"
          @pagination="getExportList"
      />
    </el-dialog>
  </div>
</template>

<script>
import { investmentData } from '@/api/starTask'
import moment from 'moment'
import {parseTime} from "@/utils/untilsMethod";
import {exportList} from "@/api/exportFile";

const statusOptions = [{ label: '订单待审核', value: 15 }, { label: '订单审核失败', value: 3 }, { label: '订单审核通过', value: 2 }, { label: '订单结束', value: 5 }]

export default {
  data() {
    return {
      loading: false,
      status: statusOptions,
      // 订单导出记录
      exportRecord: {
        open: false,
        title: '',
        dataList: []
      },
      total:0,
      queryParams: {exportType:2,page:1,size:20},
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      createTime: undefined,
      preFrom: {
        advertiser_id: undefined,
        task_ids: undefined,
        task_id: undefined,
        order_create_start_time_ms: undefined,
        order_create_end_time_ms: undefined,
        order_status: [15, 3, 2, 5],
        page_num: 1,
        page_size: 200
      },
      form: {}
    }
  },
  methods: {
    parseTime,
    handlerExportRecord() {
      return exportList({"exportType":2,"page":1,"size":20}).then(response =>{
        this.total = response.total
        this.exportRecord.dataList=response.rows
        this.exportRecord.open=true
        this.exportRecord.title="星任务(视频)订单导出记录"
      })
    },
    getExportList(){
      return exportList(this.queryParams).then(response =>{
        this.total = response.total
        this.exportRecord.dataList=response.rows
      })
    },
    onSubmit() {
      if (!this.preFrom.advertiser_id){
        this.$message.error("账户ID不能为空");
        return;
      }
      if (!this.preFrom.task_ids){
        this.$message.error("任务ID不能为空");
        return;
      }
      this.loading = true
      this.getForm()
      console.log(this.form)
      investmentData(this.form).then(response => {
        this.$message.success(response.msg)
        this.reset()
        this.loading = false
      }).catch(() => {
        this.$message.error('请求失败')
        this.loading = false
      })
    },
    reset() {
      this.form = {}
    },
    getForm() {
      if (this.preFrom.advertiser_id) {
        this.form.advertiser_id = Number(this.preFrom.advertiser_id)
      }
      if (this.preFrom.task_ids) {
        this.form.task_ids = this.preFrom.task_ids.trim().split(/[,\s]+/)
      }
      if (this.preFrom.page_num) {
        this.form.page_num = Number(this.preFrom.page_num)
      }
      if (this.preFrom.page_size) {
        this.form.page_size = Number(this.preFrom.page_size)
      }
      if (this.preFrom.order_status) {
        this.form.order_status = this.preFrom.order_status
      }
      if (this.preFrom.order_create_start_time_ms || this.preFrom.order_create_end_time_ms) {
        this.form.order_create_start_time_ms = this.preFrom.order_create_start_time_ms
        this.form.order_create_end_time_ms = this.preFrom.order_create_end_time_ms
      }
    },
    getDate(value) {
      if (this.createTime) {
        this.preFrom.order_create_start_time_ms = moment(this.createTime[0]).valueOf()
        this.preFrom.order_create_end_time_ms = moment(this.createTime[1]).valueOf()
      }
      if (value == null) {
        this.createTime = undefined // 清空开始时间和结束时间
        this.preFrom.order_create_start_time_ms = undefined
        this.preFrom.order_create_end_time_ms = undefined
      }
    }
  }
}
</script>

